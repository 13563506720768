import React from 'react'
import { graphql } from 'gatsby'
import { Layout } from '../components'
import { Seo } from '../components/base'
import { LayoutBackground } from '../components/shared'
import { ContactForm } from '../components/forms'

const ContactPage = ({ data, pageContext }) => {
  const { title, desc, sendMessage, errorMessage, contactForm } =
    data.contactPage.nodes[0]
  const languages = { pageContext }

  const layoutBackgroundHeaderComponent = (
    <div className="mx-auto max-w-6xl mb-14 md:mb-24">
      <h1 className="mb-5 md:mb-11 text-center">{title}</h1>
      <p>{desc}</p>
    </div>
  )

  return (
    <Layout standardPaddingTopFromNavbarToMain translations={languages}>
      <Seo title={title} description={desc} />
      <LayoutBackground headerComponent={layoutBackgroundHeaderComponent}>
        <section className="md:px-10 lg:px-0 max-w-6xl mx-auto">
          <ContactForm
            formData={contactForm}
            markets={data.markets.nodes}
            sendMessage={sendMessage}
            errorMessage={errorMessage}
          />
        </section>
      </LayoutBackground>
    </Layout>
  )
}

export default ContactPage

export const pageQuery = graphql`
  query ($language: String! = "de_de") {
    contactPage: allSanityContactPage(
      filter: {
        i18n_lang: { eq: $language }
        isNotAvailableInCountry: { ne: true }
      }
    ) {
      nodes {
        title
        desc
        errorMessage
        sendMessage
        image {
          ...ImageObjectQuery
        }
        contactForm {
          city {
            name
            placeholder
            required
            type
            initialValue
          }
          emailAddress {
            name
            placeholder
            required
            type
            initialValue
          }
          faxNumber {
            name
            placeholder
            required
            type
            initialValue
          }
          firstName {
            name
            placeholder
            required
            type
            initialValue
          }
          lastName {
            name
            placeholder
            required
            type
            initialValue
          }
          message {
            name
            placeholder
            required
            initialValue
          }
          outreach {
            name
            label
            options {
              initialValue
              label
              name
              required
            }
            initialValue
          }
          phoneNumber {
            name
            placeholder
            required
            type
            initialValue
          }
          privacy {
            name
            _rawLabel
            required
            initialValue
          }
          salutation {
            name
            options
            placeholder
            required
            initialValue
          }
          street {
            name
            placeholder
            required
            type
            initialValue
          }
          subject {
            name
            options
            placeholder
            required
            initialValue
          }
          zipcode {
            name
            placeholder
            required
            type
            initialValue
          }
        }
      }
    }
    markets: allSanityMarket(filter: { status: { in: ["9"] } }) {
      nodes {
        ...MarketQuery
      }
    }
  }
`
